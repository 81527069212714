  <template style="font-family:Almarai">
    <div>
      <vx-card
        ref="filterCard"
         :title="$t('Filters')"
        class="user-list-filters mb-8 p-4"
        collapse-action

      >

        <div class="vx-row">

            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4"  >
              <label style="font-weight: bold;">{{ $t("FullName") }}</label>
              <vs-input
                v-model="search.Name"
                class="w-full"
                style="margin-top: 10px;"
              />
            </div>

            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4"  >
              <label style="font-weight: bold;">{{ $t("PhoneNumber") }}</label>
              <vs-input
                v-model="search.PhoneNumber"
                class="w-full"
                style="margin-top: 10px;"
              />

            </div>



          <!-- <div style="width: 200px; margin:4px;">
            <label style="font-weight: bold;">{{ $t("UserType") }}</label>
          <v-select
            label="name"
            style="margin-top: 10px;"
            name="Name"

            v-model="search.userType"
            :options="userTypeOptions"
            @input="getvalue"
          />
        </div> -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
      <label style="font-weight: bold;">{{ $t("DateFrom") }}</label>
      <vs-input
        v-model="search.dateFrom"
        type="date"
        class="w-full"
        style="margin-top: 10px;"
      />
    </div>

    <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
      <label style="font-weight: bold;">{{ $t("DateTo") }}</label>
      <vs-input
        v-model="search.dateTo"
        type="date"
        class="w-full"
        style="margin-top: 10px;"
      />
    </div>
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
            <vs-button
              class="my-6 rounded-sm"
              color="warning"
              @click="handleSearch()"
              >{{ $t("Search") }}</vs-button
            >
          </div>
        </div>
      </vx-card>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          id="Scroll"
          pagination
          :max-items="itemsPerPage"

          :data="UsersManagementdata"
        >

          <template slot="thead">
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("Name") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Address">{{ $t("Address") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Email">{{ $t("Email") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="PhoneNumber">{{ $t("PhoneNumber") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green; " sort-key="CreatedDate">{{ $t("CreatedDate") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="PhoneNumber">{{ $t("Status") }}</vs-th>
            <!-- <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;" sort-key="Type">{{ $t("Type") }}</vs-th> -->
            <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;"> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click="DataDetails(tr)"
              >
                <vs-td>
                  <p style="font-size: 16px;font-family:almarai ;">
                    {{ tr.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p style="font-size: 16px;font-family:almarai ;">
                    {{ tr.Address }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.Email }}
                  </p>

                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate" style="direction: ltr;">
                    {{ tr.Phone }}
                  </p>

                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ new Date(tr.CreatedDate).toLocaleDateString('en-GB') }}
                  </p>

                </vs-td>
                <vs-td>

                  <p   v-if="tr.IsBlocked" class="product-name font-medium truncate" style="color: red;font-weight: bold;">
                    محظور
                  </p>

                 <p  v-else class="product-name font-medium truncate" style="color:green;font-weight: bold;">
                    مفعل
                  </p>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <feather-icon

                    icon="TrashIcon"
                    svgClasses="w-10 h-5 hover:text-primary stroke-current"
                    @click.stop="openConfirm(tr)"
                  />
                  <div class="relative inline-block">
                  <feather-icon
                  v-if="!tr.IsBlocked"
                    icon="UnlockIcon"
                    svgClasses="w-10 h-5 hover:text-primary stroke-current"
                    @click.stop="openConfirmBlock(tr)"

                  />

                  <div v-if="!tr.IsBlocked" class="tooltip">حظر المستخدم </div>
                   </div>
                  <div class="relative inline-block">
                  <feather-icon
                     v-if="tr.IsBlocked"
                   icon="LockIcon"
                   svgClasses="w-10 h-5 hover:text-primary stroke-current"
                   @click.stop="openConfirmUnBlock(tr)"

                   />
                   <div v-if="tr.IsBlocked" class="tooltip">الغاء حظر المستخدم</div>
                  </div>
                  </vs-td>

              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </template>

<script>

import { domain } from "@/gloabelConstant.js";
import moduleUsersManagement from "@/store/UsersManagement/Customer/moduleUsersManagement.js";
import moduleBrokerManagement from "@/store/UsersManagement/Broker/moduleUsersManagement.js";
import moduleServiceProviderManagement from "@/store/UsersManagement/ServiceProvider/moduleUsersManagement.js";

export default {
  components: {},
  data() {
    return {
      itemsPerPage: 10,
      rowDataForDelete:{},
      rowDataForBlock:{},
      rowDataForUnBlock:{},
      search: {
       Name: "",
       NationalId: "",
        PhoneNumber: "",
       Address:"",
       UserType:"",
       dateFrom: "",
       dateTo: ""

      },
      filterFields: [
        { label: "FullName", model: "search.name", name: "FullName" },
        { label: "IdeNumber", model: "search.nationalId", name: "IdeNumber" },
        { label: "PhoneNumber", model: "search.phoneNumber", name: "PhoneNumber" },
      ],
      userTypeOptions:[
       "مقدم خدمه","مقدم عقار","باحث عن عقار","موظف"

      ],
      baseURL: domain,

      // Updated static data



    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    UsersManagementdata() {
      debugger;

      return this.$store.state.UsersManagementList.UsersManagements;

    },
  },
  methods: {
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title:"تأكيد",
        text: "هل تريد حذف هذا المستخدم",
        acceptText: "نعم", // Accept button text in Arabic
        cancelText: "لا", // Cancel button text in Arabic
        accept: this.acceptAlert
      });
    },
    openConfirmUnBlock(data) {
      this.rowDataForUnBlock = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد", // Title in Arabic
        text: "هل تريد الغاء حظر هذا المستخدم؟", // Message in Arabic
        acceptText: "نعم", // Accept button text in Arabic
        cancelText: "لا", // Cancel button text in Arabic
        accept: this.acceptAlertUnBlock,

      });
    },
    CheckUser() {
      debugger;
          if (typeof localStorage !== 'undefined') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
              return JSON.parse(userInfo).UserType === "SuperAdmin";
            }
          }
          return false; // Default return if localStorage or userInfo is not available
        },
      checkPermission(OPName) {
    const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];

    // Ensure Page and Page.Name are not null or undefined
    return screenRolesPermissions.some(permission =>
      permission.Operation && permission.Operation.Name === OPName
    );
  },
    openConfirmBlock(data) {
      this.rowDataForBlock = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد", // Title in Arabic
        text: "هل تريد حظر هذا المستخدم؟", // Message in Arabic
        acceptText: "نعم", // Accept button text in Arabic
        cancelText: "لا", // Cancel button text in Arabic
        accept: this.acceptAlertBlock,

      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({ time:8000,
        color: "success",
        title: "تم الحذف",
        text: "تم حذف المستخدم بنجاح",
      });
    },
    acceptAlertBlock() {
      this.BlockUser(this.rowDataForBlock);
      this.$vs.notify({ time:8000,
        color: "success",
        title: "تم الحظر",
        text: "تم الحظر المستخدم بنجاح",
      });
    },
    acceptAlertUnBlock() {
      this.UnBlockUser(this.rowDataForUnBlock);
      this.$vs.notify({ time:8000,
        color: "success",
        title: "تم الغاءالحظر",
        text: "تم الغاء حظر المستخدم بنجاح",
      });
    },
    deleteData(data) {
      debugger;
      if (data.Type ==="Customer"||data.Type === "باحث عن عقار") {
        debugger;
        this.$store.dispatch("UsersManagementList/DeleteCustomer", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });


   } else if (data.Type === "Broker"||data.Type === "مقدم عقار") {
    debugger;
    this.$store.dispatch("BrokerManagementList/DeleteBroker", data.Id).then(() => {
        this.$store.dispatch("BrokerManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("BrokerManagementList/fetchDataListItems");
        });
   } else if (data.Type === "Employee"||data.Type === "موظفى النظام") {
    debugger;
    this.$store.dispatch("UsersManagementList/DeleteEmployee", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });
   } else if (data.Type === "ServiceProvider"||data.Type === "مقدم خدمات") {
    debugger
    this.$store.dispatch("ServiceProviderManagementList/DeleteServiceProvider", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });
   }

    },
    BlockUser(data) {
      debugger;
      if (data.Type ==="Customer"||data.Type === "باحث عن عقار") {
        debugger;
        this.$store.dispatch("UsersManagementList/BlockCustomer", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });


   } else if (data.Type === "Broker"||data.Type === "مقدم عقار") {
    debugger;
    this.$store.dispatch("BrokerManagementList/BlockBroker", data.Id).then(() => {
        this.$store.dispatch("BrokerManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("BrokerManagementList/fetchDataListItems");
        });
   } else if (data.Type === "Employee"||data.Type === "موظفى النظام") {
    debugger;
    this.$store.dispatch("UsersManagementList/BlockEmployee", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });
   } else if (data.Type === "ServiceProvider"||data.Type === "مقدم خدمات") {
    debugger
    this.$store.dispatch("ServiceProviderManagementList/BlockServiceProvider", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });
   }

    },
    UnBlockUser(data) {
      debugger;
      if (data.Type ==="Customer"||data.Type === "باحث عن عقار") {
        debugger;
        this.$store.dispatch("UsersManagementList/UnBlockCustomer", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });


   } else if (data.Type === "Broker"||data.Type === "مقدم عقار") {
    debugger;
    this.$store.dispatch("BrokerManagementList/UnBlockBroker", data.Id).then(() => {
        this.$store.dispatch("BrokerManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("BrokerManagementList/fetchDataListItems");
        });
   } else if (data.Type === "Employee"||data.Type === "موظفى النظام") {
    debugger;
    this.$store.dispatch("UsersManagementList/UnBlockEmployee", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });
   } else if (data.Type === "ServiceProvider"||data.Type === "مقدم خدمات") {
    debugger
    this.$store.dispatch("ServiceProviderManagementList/UnBlockServiceProvider", data.Id).then(() => {
        this.$store.dispatch("UsersManagementList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("UsersManagementList/fetchDataListItems");
        });
   }

    },
    editData(tr) {


      if (tr.Type ==="Customer"||tr.Type === "باحث عن عقار") {
        this.$router.push(`/UsersManagement/UserDetails/${tr.Id}`);
      } else if (tr.Type === "Broker"||tr.Type === "مقدم عقار") {
        this.$router.push(`/UsersManagement/UpdateBroker/${tr.Id}`);
      } else if (tr.Type === "Employee"||tr.Type === "موظفى النظام") {
        this.$router.push({ name: 'Broker', params: { id: tr.Id }  });
      } else if (tr.Type === "ServiceProvider"||tr.Type === "مقدم خدمات") {
        this.$router.push(`/UsersManagement/ServiceProviderDetails/${tr.Id}`);
      }

    },
    DataDetails(tr) {


   if (tr.Type ==="Customer"||tr.Type === "باحث عن عقار") {
     this.$router.push(`/UsersManagement/UserDetails/${tr.Id}`);
   } else if (tr.Type === "Broker"||tr.Type === "مقدم عقار") {
     this.$router.push(`/UsersManagement/Broker/${tr.Id}`);
   } else if (tr.Type === "Employee"||tr.Type === "موظفى النظام") {
     this.$router.push({ name: 'Broker', params: { id: tr.Id }  });
   } else if (tr.Type === "ServiceProvider"||tr.Type === "مقدم خدمات") {
     this.$router.push(`/UsersManagement/ServiceProviderDetails/${tr.Id}`);
   }

 },
    getvalue(){
      console.log('Selected ID:', this.userType);
    },
    handleSearch() {
       debugger;
       this.$store
         .dispatch("UsersManagementList/SearchUsersData", this.search)
         .then((res) => {
           this.UsersManagementdata = res.data;
         });
     },

     AddOrEditData(data) {
      debugger;
      if(data.id === undefined){
        data.id = 0;
      }
      this.$router.push(`/UsersManagement/UserManagmentAddOrEdit/${data.Id}`);
    },

  },
  created() {
    if (!moduleUsersManagement.isRegistered) {
      this.$store.registerModule("UsersManagementList", moduleUsersManagement);
      moduleUsersManagement.isRegistered = true;

    }
    if (!moduleServiceProviderManagement.isRegistered) {
      this.$store.registerModule("ServiceProviderManagementList", moduleServiceProviderManagement);
      moduleServiceProviderManagement.isRegistered = true;

    }
    if (!moduleBrokerManagement.isRegistered) {
      this.$store.registerModule("BrokerManagementList", moduleBrokerManagement);
      moduleBrokerManagement.isRegistered = true;

    }

    // Fetch data from store
    this.$store.dispatch("UsersManagementList/fetchDataListItems");

  },
  mounted() {
    this.isMounted = true;
  },
};
</script>


  <style lang="scss">
  #data-list-list-view small {
    color: rgb(255, 115, 0);
    text-decoration: underline rgb(255, 115, 0);
  }
  .toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #28C76F;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF6347;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
.vxCard:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    margin-left: 10%;
    z-index: 500000;
    width: 25%;
    height: 20%;
    border-top: 3px solid rgb(255, 115, 0) !important;
    border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
    border-right: 0 none rgba(255, 0, 0, 0.9) !important;
    border-left: 0 none rgba(0, 0, 0, 0.9) !important;
  }

  #data-list-list-view {
    .vs-con-table {
      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
          td {
            padding: 20px;
            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }
            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
          td.td-check {
            padding: 20px !important;
          }
        }
      }
      .custom-label {
        font-size: 10px;
        margin-bottom: 10px;
      }
      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check {
          padding: 0 15px !important;
        }
        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  .tooltip {
    visibility: hidden;
  background-color: #333; /* Dark background */
  color: #fff; /* White text */
  text-align: center; /* Align text to the right */
  border-radius: 4px;
  padding: 10px; /* Increase padding for a larger tooltip */
  font-size: 12px; /* Increase font size */
  max-width: 250px; /* Max width of the tooltip */
  width: auto; /* Allow the width to adjust based on content */
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words onto the next line if necessary */
  position: absolute;
  bottom: 125%; /* Position above the icon */
  right: 0; /* Align to the right side of the container */
  transform: translateX(0); /* Ensure the tooltip is aligned correctly */
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show tooltip on hover */
.relative:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
  </style>
